<template>
  <view-item title="库存配件">
    <template #operation>
      <button-ajax v-if="tabs.active === 'branch'" :method="operation.summary.click">查看合计</button-ajax>
      <button-ajax v-if="store.accessRightsHash.PRICE_PURCHASE && ['batch', 'warehouse'].includes(tabs.active)" :method="operation.export.click">
        导出
      </button-ajax>
      <button-ajax v-if="tabs.active === 'batch'" :method="operation.save.click">保存</button-ajax>
      <en-dropdown @command="operation.option.command">
        <en-button type="primary">操作</en-button>
        <template #dropdown>
          <en-dropdown-item command="dailystatement">库存台账</en-dropdown-item>
          <en-dropdown-item command="warming">安全库存</en-dropdown-item>
          <en-dropdown-item command="single">盘点调价</en-dropdown-item>
          <en-dropdown-item command="single">盘点调价明细</en-dropdown-item>
        </template>
      </en-dropdown>
    </template>

    <en-card body-class="h-full" class="h-full">
      <flex-box>
        <template #default="{ height }">
          <en-tabs v-model="tabs.active">
            <en-tab-pane label="批次配件" name="batch" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <form-query :method="batch.get" :model="batch.query">
                <en-form-item label="库存搜索">
                  <select-maintain
                    v-model="batch.query.inMaterialOnly"
                    :options="[
                      { label: '0库存', value: 'N' },
                      { label: '非0库存', value: 'Y' }
                    ]"
                  ></select-maintain>
                </en-form-item>
              </form-query>

              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="INVBATFD"
                    :data="batch.data"
                    :loading="batch.loading"
                    :height="height"
                    :method="batch.get"
                    pagination
                    :paging="batch.paging"
                    show-summary
                    :summary="batch.summary.data"
                    :config="batch.config"
                  >
                    <template #WAREHOUSE_SHELF="{ row }: { row: EnocloudInventoryDefinitions['InventoryQueryDto'] }">
                      <select-maintain
                        v-model="row.warehouseShelf"
                        :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['WRHSSLF']) }"
                        :props="{ label: 'name', value: 'name' }"
                        :disabled="!store.accessRightsHash.INVENTORY_WAREHOUSE_SHELF_EDIT"
                        allow-create
                        @change="batch.warehouseShelf.change(row)"
                      ></select-maintain>
                    </template>

                    <template #COMMENT="{ row }: { row: EnocloudInventoryDefinitions['InventoryQueryDto'] }">
                      <en-input
                        v-model="row.comment"
                        :disabled="!store.accessRightsHash.INVENTORY_WAREHOUSE_SHELF_EDIT"
                        @change="batch.comment.change(row)"
                      ></en-input>
                    </template>
                  </table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>

            <en-tab-pane label="仓库配件" name="warehouse" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <form-query :method="batch.get" :model="batch.query">
                <en-form-item label="库存搜索">
                  <select-maintain
                    v-model="warehouse.query.inMaterialOnly"
                    :options="[
                      { label: '0库存', value: 'N' },
                      { label: '非0库存', value: 'Y' }
                    ]"
                  ></select-maintain>
                </en-form-item>
              </form-query>

              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="INVWHFD"
                    :data="warehouse.data"
                    :loading="warehouse.loading"
                    :height="height"
                    :method="warehouse.get"
                    pagination
                    :paging="warehouse.paging"
                    show-summary
                    :summary="warehouse.summary.data"
                    :config="warehouse.config"
                  >
                    <template #GOODS_SPECIFICATION="{ row }: { row: EnocloudInventoryDefinitions['InventoryConfigQueryDto'] }">
                      <select-maintain
                        v-model="row.firstGoodsSpecificationName"
                        :options="row.specifications"
                        :props="{ label: 'name', value: 'name' }"
                        @change="warehouse.firstGoodsSpecificationName.change(row)"
                      ></select-maintain>
                    </template>

                    <template #COUNT="{ row }: { row: EnocloudInventoryDefinitions['InventoryConfigQueryDto'] }">
                      {{ formatNumber(row.count / row.firstGoodsSpecificationWeight) + row.firstGoodsSpecificationName }}
                    </template>

                    <template #AWAITING_STOCK_IN_COUNT="{ row }: { row: EnocloudInventoryDefinitions['InventoryConfigQueryDto'] }">
                      {{ formatNumber(Math.round(row.remainingStockInCount / row.firstGoodsSpecificationWeight)) }}
                    </template>

                    <template #AWAITING_TRANSFER_IN_COUNT="{ row }: { row: EnocloudInventoryDefinitions['InventoryConfigQueryDto'] }">
                      {{ formatNumber(Math.round(row.remainingStockTransferInCount / row.firstGoodsSpecificationWeight)) }}
                    </template>

                    <template #AWAITING_STOCK_OUT_COUNT="{ row }: { row: EnocloudInventoryDefinitions['InventoryConfigQueryDto'] }">
                      {{ formatNumber(Math.round(row.remainingStockOutCount / row.firstGoodsSpecificationWeight)) }}
                    </template>
                  </table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>

            <en-tab-pane label="门店配件" name="branch" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <form-query :method="batch.get" :model="batch.query">
                <en-form-item label="库存搜索">
                  <select-maintain
                    v-model="branch.query.inMaterialOnly"
                    :options="[
                      { label: '0库存', value: 'N' },
                      { label: '非0库存', value: 'Y' }
                    ]"
                  ></select-maintain>
                </en-form-item>
              </form-query>

              <flex-box>
                <template #default="{ height }">
                  <en-table :data="branch.data" :method="branch.get" :height="height" pagination :paging="branch.paging" :loading="branch.loading">
                    <en-table-column label="名称" prop="goods.name" width="120" fixed>
                      <template #default="{ row }: { row: GoodsSummaryDto }">
                        <en-button v-if="store.accessRightsHash.GOODS_EDIT" type="primary" text @click="branch.name.click(row)">
                          {{ row.goods?.name }}
                        </en-button>
                        <span v-else>{{ row.goods?.name }}</span>
                      </template>
                    </en-table-column>
                    <en-table-column label="编码" prop="goods.serialNo" width="120px" fixed></en-table-column>
                    <en-table-column label="OE号" prop="goods.oem" width="120px" fixed></en-table-column>
                    <en-table-column label="配件类别" prop="goods.category.name" width="100px"></en-table-column>
                    <en-table-column label="规格" width="130">
                      <template #default="{ row }: { row: GoodsSummaryDto }">
                        <select-maintain
                          v-model="row.goodsSpecification"
                          :options="row.goods?.specifications"
                          :props="{ label: 'name', value: '' }"
                          value-key="id"
                          :clearable="false"
                        ></select-maintain>
                      </template>
                    </en-table-column>
                    <en-table-column label="适用车型" prop="goods.primaryVehicleSpec" width="120px">
                      <template #default="{ row }: { row: GoodsSummaryDto }">
                        {{ row.goods?.primaryVehicleSpec?.join(',') }}
                      </template>
                    </en-table-column>
                    <en-table-column label="库存总数">
                      <template #default="{ row }: { row: GoodsSummaryDto }">
                        {{ `${calculator.div(row.total.count, row.goodsSpecification?.weight ?? 0, 2)}${row.goodsSpecification?.name}` }}
                      </template>
                    </en-table-column>
                    <en-table-column v-for="item of branch.branchs.data" :key="item.id" :label="item.name" width="220">
                      <template #default="{ row }: { row: GoodsSummaryDto }">
                        <en-button type="primary" text>
                          {{
                            `${calculator.div(find(row.totals, ['branch.id', item.id])?.count ?? 0, row.goodsSpecification?.weight ?? 0, 2)}${
                              row.goodsSpecification?.name
                            }`
                          }}
                        </en-button>
                      </template>
                    </en-table-column>
                    <en-table-column label="库存均价" prop="total.price" v-if="store.accessRightsHash.PRICE_PURCHASE" width="140">
                      <template #default="{ row }: { row: GoodsSummaryDto }">
                        {{ formatMoney(row.total.price) }}
                      </template>
                    </en-table-column>
                    <en-table-column label="库存金额" prop="total.amount" v-if="store.accessRightsHash.PRICE_PURCHASE" width="140">
                      <template #default="{ row }: { row: GoodsSummaryDto }">
                        {{ formatMoney(row.total.amount) }}
                      </template>
                    </en-table-column>
                    <en-table-column label="品质类型" prop="goods.type.message"></en-table-column>
                    <en-table-column label="品牌" prop="goods.brand"></en-table-column>
                    <en-table-column label="配件型号" prop="goods.model" width="120"></en-table-column>
                    <en-table-column label="配件条码" prop="goods.barcode" width="120"></en-table-column>
                    <en-table-column label="产地" prop="goods.placeOfProduction"></en-table-column>
                    <en-table-column label="备注" prop="goods.comment"></en-table-column>
                  </en-table>
                </template>
              </flex-box>
            </en-tab-pane>
          </en-tabs>
        </template>
      </flex-box>
    </en-card>
  </view-item>

  <en-drawer v-model="summary.visible" title="总计">
    <div class="flex flex-col gap-4 text-lg">
      <div v-for="item of summary.list.data" class="flex flex-col">
        <span>{{ item.branch?.shortName }}</span>
        <span class="text-primary">库存总数：{{ item.count }}</span>
        <span class="text-primary">库存总金额：{{ formatMoney(item.amount) }}</span>
      </div>
    </div>

    <template #footer>
      <en-button @click="summary.footer.cancel.click">取消</en-button>
    </template>
  </en-drawer>

  <inventory-dailystatement v-model="dailystatement.visible"></inventory-dailystatement>

  <en-dialog v-model="warming.visible" title="安全库存" width="70%">
    <template #operation>
      <button-download
        :ajax="{ action: 'GET /enocloud/inventory/warming/export', params: (params) => (params.payload = warming.$ajaxParams.payload) }"
      >
        导出
      </button-download>
    </template>

    <table-dynamic
      code="INVWMFD"
      :height="600"
      :data="warming.data"
      :loading="warming.loading"
      :method="warming.get"
      pagination
      :paging="warming.paging"
    ></table-dynamic>

    <template #footer>
      <en-button @click="warming.footer.cancel.click">取消</en-button>
    </template>
  </en-dialog>
</template>

<script lang="ts">
import { calculator } from '@enocloud/utils'
import { find } from 'lodash-es'

import InventoryDailystatement from '@accessory/components/inventory-dailystatement.vue'

type GoodsSummaryDto = EnocloudInventoryDefinitions['GoodsSummaryDto'] & {
  specifications?: EnocloudInventoryDefinitions['GoodsSpecificationDto'][]
  goodsSpecification?: EnocloudInventoryDefinitions['GoodsSpecificationDto']
}

let batchUpdateRows: EnocloudInventoryDefinitions['InventoryQueryDto'][] = []

export default factory({
  components: { InventoryDailystatement },

  methods: { find },

  config: {
    children: {
      operation: {
        children: {
          export: {
            ajax: {
              warehouse: {
                action: 'GET /enocloud/inventory/summary/warehousegoods/export',
                params(params) {
                  params.payload = this.warehouse.$ajaxParams.payload
                }
              },
              batch: {
                action: 'GET /enocloud/inventory/export',
                params(params) {
                  params.payload = this.batch.$ajaxParams.payload
                }
              }
            },
            async click() {
              if (this.tabs.active === 'warehouse') {
                await this.operation.export.warehouse()
              } else if (this.tabs.active === 'batch') {
                await this.operation.export.batch()
              }
              this.store.openDownload()
            }
          },
          summary: {
            async click() {
              if (this.tabs.active !== 'branch') return
              return this.summary.list.get().then(() => (this.summary.visible = true))
            }
          },
          save: {
            async click() {
              if (this.tabs.active !== 'batch' || !batchUpdateRows.length) return
              await this.batch.submit()
              batchUpdateRows = []
              return this.batch.get()
            }
          },
          option: {
            command(option: string) {
              switch (option) {
                case 'dailystatement':
                  this.dailystatement.visible = true
                  break
                case 'warming':
                  this.warming.get()
                  this.warming.visible = true
                  break
              }
            }
          }
        }
      },
      tabs: {
        active: 'batch'
      },
      batch: {
        query: {
          inMaterialOnly: 'Y'
        },
        ajax: {
          get: {
            action: 'GET /enocloud/inventory/query',
            summary: 'GET /enocloud/inventory/summary',
            loading: true,
            pagination: true,
            data: 'array',
            params(params) {
              params.payload = this.batch.query
            }
          },
          submit: {
            action: 'POST /enocloud/inventory/serviceprice',
            loading: true,
            params(params) {
              params.data = batchUpdateRows
            }
          }
        },
        config: {
          GOODS_SERIAL_NO: { header: { filter: { type: 'text', field: 'serialNo' } } },
          GOODS_BARCODE: { header: { filter: { type: 'text', field: 'barcode' } } },
          GOODS_OEM: { header: { filter: { type: 'text', field: 'oem' } } },
          GOODS_NAME: { header: { filter: { type: 'text', field: 'name' } } },
          WAREHOUSE_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'warehouseId',
                props: {
                  ajax: { action: 'GET /enocloud/common/warehouse', params: (value) => ({ name: value }) },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          },
          GOODS_CATEGORY_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'categoryId',
                props: {
                  ajax: { action: 'GET /enocloud/common/goods/category', params: (value) => ({ name: value }) },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          },
          SUPPLIER_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'supplierId',
                props: {
                  ajax: { action: 'GET /enocloud/common/customer', params: (value) => ({ name: value, type: 'B' }) },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          },
          GOODS_BRAND: { header: { filter: { type: 'text', field: 'brand' } } },
          DATETIME: { header: { filter: { type: 'date', field: ['startDate', 'endDate'], props: { type: 'daterange' } } } },
          GOODS_VEHICLE_SPEC: { header: { filter: { type: 'text', field: 'primaryVehicleSpec' } } },
          GOODS_MODEL: { header: { filter: { type: 'text', field: 'model' } } },
          BATCH_NO: { header: { filter: { type: 'text', field: 'batchNo' } } },
          WAREHOUSE_SHELF: { header: { filter: { type: 'text', field: 'warehouseShelf' } } },
          COMMENT: { header: { filter: { type: 'text', field: 'comment' } } },
          PURCHASE_COMMENT: { header: { filter: { type: 'text', field: 'purchaseComment' } } },
          PLATE_NO: { header: { filter: { type: 'text', field: 'plateNo' } } }
        },
        children: {
          comment: {
            change(row: EnocloudInventoryDefinitions['InventoryQueryDto']) {
              const exist = find(batchUpdateRows, ['id', row.id])
              if (!exist) batchUpdateRows.push(row)
            }
          },
          warehouseShelf: {
            change(row: EnocloudInventoryDefinitions['InventoryQueryDto']) {
              const exist = find(batchUpdateRows, ['id', row.id])
              if (!exist) batchUpdateRows.push(row)
            }
          }
        }
      },
      warehouse: {
        query: {
          inMaterialOnly: 'Y'
        },
        ajax: {
          get: {
            action: 'GET /enocloud/inventory/summary/warehousegoods',
            summary: 'GET /enocloud/inventory/summary/warehousegoods/summary',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.warehouse.query
            }
          }
        },
        config: {
          GOODS_SERIAL_NO: { header: { filter: { type: 'text', field: 'serialNo' } } },
          GOODS_BARCODE: { header: { filter: { type: 'text', field: 'barcode' } } },
          GOODS_OEM: { header: { filter: { type: 'text', field: 'oem' } } },
          GOODS_NAME: { header: { filter: { type: 'text', field: 'name' } } },
          GOODS_BRAND: { header: { filter: { type: 'text', field: 'brand' } } },
          GOODS_VEHICLE_SPEC: { header: { filter: { type: 'text', field: 'primaryVehicleSpec' } } },
          GOODS_MODEL: { header: { filter: { type: 'text', field: 'model' } } },
          WAREHOUSE_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'warehouseId',
                props: {
                  ajax: { action: 'GET /enocloud/common/warehouse', params: (value) => ({ name: value }) },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          },
          SUPPLIER_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'supplierId',
                props: {
                  ajax: { action: 'GET /enocloud/common/customer', params: (value) => ({ name: value, type: 'B' }) },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          },
          GOODS_CATEGORY_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'categoryId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/goods/category',
                    params: (value) => ({ name: value })
                  },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          }
        },
        children: {
          firstGoodsSpecificationName: {
            change(row: EnocloudInventoryDefinitions['InventoryConfigQueryDto']) {
              row.firstGoodsSpecificationWeight = find(row.specifications, ['name', row.firstGoodsSpecificationName])?.weight ?? 0
            }
          }
        }
      },
      branch: {
        query: {
          inMaterialOnly: 'Y'
        },
        ajax: {
          get: {
            action: 'GET /enocloud/inventory/summary/goods',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.branch.query
            },
            convert(data: GoodsSummaryDto[], res: EnocloudInventoryDefinitions['DataResponse«GoodsSummaryDto»']) {
              this.branch.branchs.data = (res.extraData as { branch: EnocloudCommonDefinitions['BranchDto'][] }).branch
              return data.map((item) => {
                item.goodsSpecification = structuredClone(find(item.goods?.specifications, ['weight', 1]))
                return item
              })
            }
          }
        },
        children: {
          branchs: {
            data: [] as EnocloudCommonDefinitions['BranchDto'][],
            count(row: EnocloudInventoryDefinitions['GoodsSummaryDto'], branch: EnocloudCommonDefinitions['BranchDto']) {
              const exist = find(row.totals, ['branch.id', branch.id])
              const spec = find(row.goods?.specifications, ['weight', 1])
              // return exist ? `${calculator.div(exist.count, row.)}` : ''
            }
          },
          name: {
            click(row: EnocloudInventoryDefinitions['GoodsSummaryDto']) {}
          }
        }
      },
      summary: {
        visible: false,
        children: {
          list: {
            ajax: {
              get: {
                action: 'GET /enocloud/inventory/summary/goods/summary',
                data: 'array',
                loading: true
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.summary.visible = false
              }
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {}
      },
      dailystatement: {
        visible: false
      },
      warming: {
        visible: false,
        ajax: {
          get: {
            action: 'GET /enocloud/inventory/warming',
            data: 'array',
            loading: true,
            pagination: true
          }
        },
        children: {
          footer: {
            cancel: {
              click() {
                this.warming.visible = false
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.batch.get()
    this.warehouse.get()
    this.branch.get()
  }
})
</script>
